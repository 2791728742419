<template>
<div class="d-flex h-100 align-items-center text-center">
    <div class="p-4 container">
        <div class="mb-3">
            <span class="material-icons-outlined h1">cloud_off</span>
        </div>
        <div class="h2 mb-1">You're offline.</div>
        <div class="text-muted">Reconnect to continue using Amino.</div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Offline',
    data() {
        return {};
    }
}
</script>

<style lang="scss" scoped>
	.container {
		max-width: 425px;
	}
</style>
