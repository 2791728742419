<template>
	<div class="h-100" id="app" v-if="loaded">
		<script v-html="richSnippet" v-if="richSnippet" type="application/ld+json"></script>
		<!-- <transition name="component-fade" mode="out-in">

		</transition> -->
		<router-view  v-if="isOnline"></router-view>
		<Offline v-else />
		<!-- <CookieNotice /> -->
	</div>
</template>

<script>
	import { makeFindMixin } from 'feathers-vuex';
	import { VueOfflineMixin } from 'vue-offline';
	import Offline from '@/components/Offline';
	// const CookieNotice = () => import('@/components/CookieNotice');
	export default {
		name: 'App',
		mixins: [ VueOfflineMixin, makeFindMixin({ service: 'coupons' }) ],
		components: {
			Offline
		},
		data () {
			return {
				loaded: false
			}
		},
		computed: {
			couponsParams() {
				return { query: {} } // Step 3
			},
			richSnippet () {
				if (this.$route.meta && this.$route.meta.seo && this.$route.meta.seo.richSnippet) {
					return this.$route.meta.seo.richSnippet;
				}
				return false;
			}
		},
		methods: {
			detectAuth (change) {
				if (change.key === 'feathers-jwt') {
					location.href = '/sign-in';
				}
			},
			loadOnlineServices () {
				this.$router.onReady(() => {
					if (this.$route.name != 'coming-soon') {
						this.loadGoogleOneTap();
					}
				});
			}
		},
		async mounted () {
			if (this.isOnline) {
				this.loadOnlineServices();
			}
			this.$on('online', () => {
				this.loadOnlineServices();
			});
			this.$on('offline', () => {
				this.removeGoogleOneTap();
			});
			window.addEventListener('storage', this.detectAuth);
			this.loaded = true;
		},
		unmounted () {
			window.removeEventListener('storage', this.detectAuth);
		}
	}
</script>

<style lang="scss">
	@import '../node_modules/bootstrap/scss/bootstrap';
	@import '../node_modules/bootstrap-vue/src/index.scss';
	@import '../node_modules/prismjs/themes/prism.css';
	@import '/assets/css/source.scss';
</style>
